import { EditIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalContent,
  ModalHeader,
  Collapse,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tooltip,
  IconButton,
  useToast,
  Center,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { Properties } from "promuc-table";
import React, { useState } from "react";
import {
  IoAddSharp,
  IoBanSharp,
  IoPencilSharp,
  IoSaveSharp,
  IoTrashBinSharp,
} from "react-icons/io5";
import DynamicForm from "../../../components/DynamicForm";
import TableWithState from "../../../components/TableWithState";
import { classID, fieldID, linkID, linkTypeID } from "../../../utils/constants";
import { makeNewEntity } from "../../../utils/functions";
import useStore from "../../../utils/store";
import ConfirmDelete from "./ConfirmDelete";

export function ButtonsAndModal({
  isDelete,
  onCancel,
  deleteItem,
  edit,
  setMake,
  openDelete,
  chosenItem,
  onAddNew = () => {},
}: {
  isDelete: boolean;
  onCancel: () => void;
  deleteItem: () => void;
  edit: boolean;
  setMake: React.Dispatch<React.SetStateAction<boolean>>;
  openDelete: () => void;
  chosenItem: any;
  onAddNew?: () => void;
}) {
  const disabled = Object.keys(chosenItem).length < 1;

  return (
    <Collapse in={edit} animateOpacity>
      <ConfirmDelete
        isOpen={isDelete}
        onClose={onCancel}
        deleteItem={deleteItem}
      />
      <Stack>
        <Button
          leftIcon={<IoAddSharp />}
          onClick={() => {
            setMake(true);
            onAddNew();
          }}
          variant="outline"
        >
          Добавить элемент
        </Button>
        <Button
          leftIcon={<IoPencilSharp />}
          onClick={() => setMake(true)}
          variant="outline"
          isDisabled={disabled}
        >
          Редактировать выбранный элемент
        </Button>
        <Button
          leftIcon={<IoTrashBinSharp />}
          variant="outline"
          onClick={openDelete}
          isDisabled={disabled}
        >
          Удалить выбранный элемент
        </Button>
      </Stack>
    </Collapse>
  );
}

export default function ClassProps({
  isOpen,
  onClose,
  data,
}: {
  isOpen: boolean;
  onClose: () => void;
  data: any;
}) {
  const {
    isOpen: isDelete,
    onOpen: onDelete,
    onClose: onCancel,
  } = useDisclosure();
  
  const [edit, setEdit] = useState(false);
  const [makeField, setMakeField] = useState(false);
  const [makeLink, setMakeLink] = useState(false);

  const [error, setError] = useState(false);
  const [fieldErr, setFieldErr] = useState(false);
  const [linkErr, setLinkErr] = useState(false);

  const [base, setBase] = useState(data.item);
  const [field, setField] = useState<Properties | {}>({});
  const [link, setLink] = useState<Properties | {}>({});

  const toast = useToast();

  const selfInfo = {
    numbers: [],
    booleans: [],
    required: [],
  };

  const fieldInfo = {
    numbers: [],
    booleans: [],
    required: [],
  };

  const linkInfo = {
    numbers: [],
    booleans: [],
    required: [],
  };

  const outerStorage = useStore((state) => state.outerStorage);
  const allProps = outerStorage?.getFullClassInfo(data.item.id);
  let fields = allProps?.filter((i) => {
    return i["owner_id"] === fieldID;
  });
  fields = fields?.map((field: any) => {
    const newItem = field;
    if (!newItem.hasOwnProperty("type_id")) {
      newItem["type_id"] = field.type.id;
      delete newItem.type;
    }
    return newItem;
  });
  let links = allProps?.filter((i) => {
    return i["owner_id"] === linkID;
  });
  links = links?.map((link: any) => {
    const newItem = link;
    if (!newItem.hasOwnProperty("type_id")) {
      newItem["type_id"] = link.type.id;
      delete newItem.type;
    }
    return newItem;
  });
  const classProps = outerStorage?.getFullClassInfo(classID)?.filter((i) => {
    return i.mnemo !== "owner_id" && i.mnemo !== "id";
  });
  const fieldProps = outerStorage?.getFullClassInfo(fieldID)?.filter((i) => {
    return i.mnemo !== "owner_id" && i.mnemo !== "id" && i.mnemo !== "class_id";
  });
  const linkProps = outerStorage?.getFullClassInfo(linkID)?.filter((i) => {
    return (
      i.mnemo !== "owner_id" &&
      i.mnemo !== "id" &&
      i.mnemo !== "type_id" &&
      i.mnemo !== "class_id"
    );
  });

  function deleteItem(item: Properties) {
    outerStorage?.deleteData(item.id).then((data) => {
      console.log(data, "deleted!");
      toast({
        title: "Удалено",
        description: "Элемент успешно удалён",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    });
  }

  function saveItem(
    item: any,
    info: { numbers: string[]; booleans: string[]; required: string[] },
    addFields: (item: any) => any,
    callback: (item: any) => void
  ) {
    makeNewEntity(
      info.numbers,
      info.booleans,
      info.required,
      item,
      addFields,
      () => {
        outerStorage?.putData(item).then((data: any) => {
          console.log("saved!");
          callback(item);
          toast({
            title: "Сохранено",
            description: "Результат успешно сохранен",
            status: "info",
            duration: 3000,
            isClosable: true,
          });
        });
      }
    );
  }

  function saveBase() {
    saveItem(
      base,
      selfInfo,
      (item) => item,
      (item) => {
        data.item = item;
      }
    );
  }

  function saveField() {
    saveItem(
      field,
      fieldInfo,
      (item) => {
        item["owner_id"] = fieldID;
        item["class_id"] = data.item.id;
        return item;
      },
      () => {
        data.props = outerStorage?.getFullClassInfo(data.item.id);
      }
    );
  }

  function saveLink() {
    saveItem(
      link,
      linkInfo,
      (item) => {
        item["owner_id"] = linkID;
        item["type_id"] = linkTypeID;
        item["class_id"] = data.item.id;
        return item;
      },
      () => {
        data.props = outerStorage?.getFullClassInfo(data.item.id);
      }
    );
  }

  function exitEdit() {
    setEdit(false);
    setLink({});
    setField({});
    setMakeField(false);
    setMakeLink(false);
    toast({
      title: "Редактирование завершено",
      description: "Выход из режима редактирования свойств класса",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  }

  function startEdit() {
    setEdit(true);
    toast({
      title: "В редактировании",
      description: "Вход в режим редактирования свойств класса",
      status: "warning",
      duration: 3000,
      isClosable: true,
    });
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full" closeOnEsc={!edit}>
      <ModalContent display="flex">
        <ModalHeader>{`Поля и связи объектов класса ${data.item["name"]}`}</ModalHeader>
        <Collapse in={!edit}>
          <ModalCloseButton />
        </Collapse>
        <ModalBody>
          <Tabs
            variant="enclosed-colored"
            isFitted
            isLazy
            onChange={() => {
              setField({});
              setLink({});
            }}
          >
            <TabList overflowX={"auto"} pb={1} borderBottomWidth={0}>
              <Tab>Базовые свойства</Tab>
              <Tab>Поля класса</Tab>
              <Tab>Связи с классами</Tab>
              <Tab>Представление</Tab>
            </TabList>
            <TabPanels>
              {/* Базовые свойства */}
              <TabPanel>
                <DynamicForm
                  setError={setError}
                  classProps={classProps}
                  required={selfInfo.required}
                  booleans={selfInfo.booleans}
                  numbers={selfInfo.numbers}
                  item={data.item}
                  setItem={setBase}
                  isEdit={edit}
                />
                <Collapse in={edit} animateOpacity>
                  <Center>
                    <Button
                      leftIcon={<IoSaveSharp />}
                      variant="outline"
                      my={2}
                      onClick={saveBase}
                      isDisabled={error}
                    >
                      Сохранить изменения
                    </Button>
                  </Center>
                </Collapse>
              </TabPanel>
              <TabPanel
                overflow={"auto"}
                my={4}
                mx={2.5}
                border="1px"
                borderColor="gray.600"
                borderRadius="0.5em"
                padding={0}
                maxH={"33em"}
              >
                {/* Поля */}
                {makeField ? (
                  <>
                    <DynamicForm
                      setError={setFieldErr}
                      classProps={fieldProps}
                      required={fieldInfo.required}
                      booleans={fieldInfo.booleans}
                      numbers={fieldInfo.numbers}
                      item={field}
                      setItem={setField}
                      isEdit={edit}
                    />
                    <Collapse in={edit} animateOpacity>
                      <Stack>
                        <Button
                          leftIcon={<IoSaveSharp />}
                          variant="outline"
                          my={2}
                          onClick={saveField}
                          isDisabled={fieldErr}
                        >
                          Сохранить изменения
                        </Button>
                        <Button
                          leftIcon={<IoBanSharp />}
                          variant="outline"
                          my={2}
                          onClick={() => {
                            setField({});
                            setMakeField(false);
                          }}
                        >
                          Отменить создание
                        </Button>
                      </Stack>
                    </Collapse>
                  </>
                ) : (
                  <>
                    <TableWithState
                      entities={fields}
                      properties={fieldProps}
                      onChoose={(item) => {
                        setField(item);
                      }}
                    />

                    <ButtonsAndModal
                      isDelete={isDelete}
                      onCancel={onCancel}
                      deleteItem={() => deleteItem(field as Properties)}
                      edit={edit}
                      setMake={setMakeField}
                      openDelete={onDelete}
                      chosenItem={field}
                    />
                  </>
                )}
              </TabPanel>
              <TabPanel
                overflow={"auto"}
                my={4}
                mx={2.5}
                border="1px"
                borderColor="gray.600"
                borderRadius="0.5em"
                padding={0}
              >
                {/* Связи с классами */}
                {makeLink ? (
                  <>
                    <DynamicForm
                      setError={setLinkErr}
                      classProps={linkProps}
                      required={linkInfo.required}
                      booleans={linkInfo.booleans}
                      numbers={linkInfo.numbers}
                      item={link}
                      setItem={setLink}
                      isEdit={edit}
                    />
                    <Collapse in={edit} animateOpacity>
                      <Center>
                        <Button
                          leftIcon={<IoSaveSharp />}
                          variant="outline"
                          onClick={saveLink}
                          isDisabled={linkErr}
                        >
                          Сохранить изменения
                        </Button>
                        <Button
                          leftIcon={<IoBanSharp />}
                          variant="outline"
                          onClick={() => {
                            setLink({});
                            setMakeLink(false);
                          }}
                        >
                          Отменить создание
                        </Button>
                      </Center>
                    </Collapse>
                  </>
                ) : (
                  <>
                    <TableWithState
                      entities={links}
                      properties={linkProps}
                      onChoose={(item) => {
                        setLink(item);
                      }}
                    />

                    <ButtonsAndModal
                      isDelete={isDelete}
                      onCancel={onCancel}
                      deleteItem={() => deleteItem(link as Properties)}
                      edit={edit}
                      setMake={setMakeLink}
                      openDelete={onDelete}
                      chosenItem={link}
                    />
                  </>
                )}
              </TabPanel>
              <TabPanel>
                {"{"}
                {data.props.map((p: any) => {
                  return <Text pl={5}>{`${p.mnemo}: ${p.type.name}`}</Text>;
                })}
                {"}"}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          {edit === false && (
            <Tooltip label="Редактировать">
              <IconButton onClick={startEdit} aria-label="edit form">
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {edit === true && (
            <Tooltip label="Закончить редактирование">
              <IconButton onClick={exitEdit} aria-label="quit edit">
                <IoBanSharp />
              </IconButton>
            </Tooltip>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
