import {
  FormControl,
  FormErrorMessage,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  Spinner,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Properties } from "promuc-table";
import useStore from "../utils/store";
import MultiSelect from "./MultiSelect";

function LinkField(props: {
  isArray: boolean;
  item: any;
  prop: any;
  setItem: React.Dispatch<any>;
}) {
  const { isArray, item, prop, setItem } = props;
  const [options, setOptions] = useState<{ id: string; name: string }[]>([]);
  const [loading, setLoading] = useState(true);
  const outerStorage = useStore((state) => state.outerStorage);

  useEffect(() => {
    if (prop.link_class_id) {
      outerStorage
        ?.getByQueryList({
          cls: prop.link_class_id,
          isFull: true,
        })
        .then((data) => {
          const res = data.map((item: any) => ({
            id: item.id,
            name: item.name ? item.name : item.mnemo ? item.mnemo : item.id,
          }));
          setOptions(res);
          setLoading(false);
        });
    }
  }, [outerStorage, prop.link_class_id]);

  if (isArray) {
    return (
      <>
        {loading && <Spinner />}
        <MultiSelect
          label="Элементы"
          options={options}
          startOptions={item ? item[prop.mnemo] : []}
          onChange={(valueString) =>
            setItem((currentState: any) => ({
              ...currentState,
              [prop.mnemo]: valueString,
            }))
          }
        />
      </>
    );
  } else {
    return (
      <>
        {loading && <Spinner />}
        <Select
          defaultValue={item ? item[prop.mnemo] : 0}
          onChange={(e) =>
            setItem((currentState: any) => ({
              ...currentState,
              [prop.mnemo]: e.target.value,
            }))
          }
        >
          <option value={0} disabled>
            Нет значения
          </option>
          {options.map((opt: any) => (
            <option value={opt.id}>{opt.name}</option>
          ))}
        </Select>
      </>
    );
  }
}

function FieldsForEdit(props: {
  item: any;
  classProps: Properties[] | undefined;
  numbers?: string[];
  booleans?: string[];
  required?: string[];
  setItem?: React.Dispatch<any>;
  setError?: React.Dispatch<any>;
}) {
  const {
    item,
    classProps = [],
    numbers = [],
    booleans = [],
    required = [],
    setItem = () => {},
    setError = () => {}
  } = props;

  useEffect(() => {
    let fail = false;
    classProps?.forEach((prop: any) => {
      const propType = prop.type;

      const err =
        prop.required && !item[prop.mnemo] && propType.mnemo !== "bool";
      if (err){
        fail = true
      }
    });
    setError(fail);
  }, [item, classProps, setError]);

  console.log(item)

  return (
    <>
      {classProps?.map((prop: any, index: number) => {
        const propType = prop.type;

        const error =
          prop.required && !item[prop.mnemo] && propType.mnemo !== "bool";

          console.log(error)

        if (prop.required) {
          required.push(prop.mnemo);
        }
        let customInput;

        switch (propType.mnemo) {
          case "int":
          case "double":
            numbers.push(prop.mnemo);
            customInput = (
              <NumberInput
                size="sm"
                defaultValue={item ? item[prop.mnemo] : ""}
                onChange={(valueString) =>
                  setItem((currentState: any) => ({
                    ...currentState,
                    [prop.mnemo]: valueString,
                  }))
                }
              >
                <NumberInputField />
              </NumberInput>
            );
            break;
          case "bool":
            booleans.push(prop.mnemo);
            customInput = (
              <Switch
                defaultChecked={item ? item[prop.mnemo] : false}
                onChange={(e) =>
                  setItem((currentState: any) => ({
                    ...currentState,
                    [prop.mnemo]: e.target.checked,
                  }))
                }
              />
            );
            break;
          case "text":
          case "string":
            customInput = (
              <Input
                defaultValue={item ? item[prop.mnemo] : ""}
                onChange={(e) =>
                  setItem((currentState: any) => ({
                    ...currentState,
                    [prop.mnemo]: e.target.value,
                  }))
                }
                size="sm"
                placeholder="Введите или оставьте пустым"
              />
            );
            break;
          case "date":
            customInput = (
            <Input 
            size="sm"
            defaultValue={item ? item[prop.mnemo] : new Date()}
            type="datetime-local" 
            onChange={(e) =>
              setItem((currentState: any) => ({
                ...currentState,
                [prop.mnemo]: new Date(e.target.value).getTime(),
              }))} />
            );
            break;
          case "link":
            numbers.push(prop.mnemo);
            customInput = (
              <LinkField
                isArray={prop.is_array}
                item={item}
                prop={prop}
                setItem={setItem}
              />
            );
            break;
          default:
            customInput = <Text>{propType.mnemo}</Text>;
            break;
        }
        return (
          <Tr>
            <Td textAlign="end" width="50%">{`${prop.name}(${prop.mnemo}):${
              prop.required ? "*" : ""
            }`}</Td>
            <Td>
              <FormControl isInvalid={error}>
                {customInput}
                {error && (
                  <FormErrorMessage>
                    Поле обязательно к заполнению, пожалуйста, введите данные
                  </FormErrorMessage>
                )}
              </FormControl>
            </Td>
          </Tr>
        );
      })}
    </>
  );
}


type DynamicFormProps = {
  classProps: Properties[] | undefined;
  numbers?: string[];
  booleans?: string[];
  required?: string[];
  item?: any;
  setItem?: React.Dispatch<any>;
  setError?: React.Dispatch<any>;
  isEdit?: boolean;
};

export default function DynamicForm(props: DynamicFormProps) {
  const {
    classProps,
    numbers = [],
    booleans = [],
    required = [],
    item = {},
    setItem = () => {},
    setError = () => {},
    isEdit = true,
  } = props;

  return (
    <TableContainer border="1px" borderColor="gray.600" borderRadius="0.5em">
      <Table colorScheme="blue">
        <Thead>
          <Tr>
            <Th textAlign="end">Свойство</Th>
            <Th>Значение</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isEdit ? (
            <FieldsForEdit
              classProps={classProps}
              item={item}
              numbers={numbers}
              booleans={booleans}
              required={required}
              setItem={setItem}
              setError={setError}
            />
          ) : (
            <>
              <Tr>
                <Td textAlign="end" width="50%" height="32px">
                  ID:
                </Td>
                <Td>{item ? item["id"]?.toString() : undefined}</Td>
              </Tr>
              {classProps?.map((prop: any) => {
                // TODO: отображать по ссылкам вместо id название, id только в крайнем случае
                return (
                  <Tr>
                    <Td
                      textAlign="end"
                      width="50%"
                      height="32px"
                    >{`${prop.name}(${prop.mnemo}):`}</Td>
                    <Td>{item ? item[prop.mnemo]?.toString() : undefined}</Td>
                  </Tr>
                );
              })}
            </>
          )}
        </Tbody>
      </Table>
      <Text m={5}>* - обязательно к заполнению</Text>
    </TableContainer>
  );
}
