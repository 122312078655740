// TODO: надо потестить этот алгос на различных графах,
// на 01.09.2022 пока что имеется достаточно простое дерево для отрисовки

import { Collection } from "immutable";
import _ from "lodash";
import DataWorker, { Classes } from "promuc-table";
import { classID, objID } from "./constants";

/**
 * Функция для рекурсивного обхода части дерева в попытке узнать насколько глубоко находится узел
 *
 * @param item денормализованный узел, для которого ведется поиск
 * @param allClasses Все возможные классы
 * @param depthCounter значение счетчика глубины
 * @returns глубина узла. Самая большая из имеющихся потомков
 */
export function findHierarcyDepth(
  item: any,
  allClasses: any,
  depthCounter: number
): number {
  if (item["extends"].length === 0) {
    return depthCounter;
  } else {
    let level = depthCounter;
    for (let i = 0; i < item["extends"].length; i++) {
      const parentId = item["extends"][i];
      const parent = allClasses.find((c: Classes) => c.id === parentId);
      const recursiveLevel = findHierarcyDepth(
        parent,
        allClasses,
        depthCounter + 1
      );
      if (level < recursiveLevel) level = recursiveLevel;
    }
    return level;
  }
}

/**
 * Рекурсивный обход узлов-классов в попытке узнать насколько "глубокие" связи у класса
 * @param item собственно класс
 * @param findProps функция нахождния полей
 */
export function findRelationDepth(item: any, storage: DataWorker | undefined, initDepth = 0) {
  const props = storage?.getFullClassInfo(item);
  console.log(initDepth, item)
  let returnDepth = initDepth;
  props?.forEach((prop: any, index: number) => {
    // Класс имеет связь, не ссылающуюся на себя.
    // Также класс не Объект, который, ссылаясь на Класс, образует петлю в графе
    if (
      prop.type.mnemo === "link" &&
      prop.link_class_id !== item &&
      item !== objID &&
      item !== classID
    ) {
      const findDepth = findRelationDepth(prop.link_class_id , storage, initDepth + 1);
      if (findDepth > returnDepth) returnDepth = findDepth;
    }
    return;
  });
  return returnDepth;
}

export function changeOrCreateNew(
  instance: any[],
  imm: Collection<unknown, unknown>
) {
  const currMap = instance;
  let hasFind = false;
  const newMap = currMap.map((obj) => {
    if (obj.id === imm.get("id")) {
      hasFind = true;
      return imm.toJS();
    }
    return obj;
  });
  if (!hasFind) {
    newMap.push(imm.toJS());
  }
  return newMap;
}

export function makeNewEntity(
  numbers: string[],
  booleans: string[],
  required: string[],
  newItem: any,
  addFields: (item: any) => any,
  callback: () => void
) {
  // Преобразуем строки в числа
  numbers.forEach((n: string) => {
    if (Array.isArray(newItem[n])) {
      newItem[n] = newItem[n].map((item: any) => Number(item) || 0);
    } else {
      newItem[n] = Number(newItem[n] || 0);
    }
  });
  // Преобразуем строки в логические выражения
  booleans.forEach((b: string) => (newItem[b] = Boolean(newItem[b] || false)));

  // Добавляем ели это требуется поля, которые лучше пользователю не указывать
  newItem = addFields(newItem);

  // проверяем по всем обязательным нелогическим значениям
  // TODO: проверить на multiselect и select, numberInput равен 0 если пуст
  let hasEmptyFields = false;
  const nonBooleanRequired = _.differenceBy(required, [
    ...booleans,
    ...numbers,
  ]);

  nonBooleanRequired.forEach((req) => {
    if (!newItem[req]) {
      console.warn(
        "Required field ",
        req,
        " is empty but required. value is:",
        newItem[req]
      );
      hasEmptyFields = true;
    }
  });
  // ошибок нет, минимальный набор данных есть, можем доабвляться
  if (!hasEmptyFields) {
    callback();
  }
}
