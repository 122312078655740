import { SettingsIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Handle, Position } from "react-flow-renderer";
import { darkEdgesColor, lightEdgesColor } from "../../../styles/colors";
import { ShowMode, userClassIDmin } from "../../../utils/constants";
import useStore from "../../../utils/store";
import ClassProps from "../modals/ClassProps";
import Block from "./ChakraNode";

export type Abstract = {
  data: {
    item: any;
    props: any;
  };
  selected: boolean;
};

export default function AbstractNode(props: Abstract) {
  const { data, selected } = props;

  const { isOpen, onOpen, onClose } = useDisclosure(); // for modal window with props

  const bg = useColorModeValue(lightEdgesColor, darkEdgesColor);

  const id = data.item.id;
  const mode = useStore((state) => state.showMode);

  return (
    <Block
      variant={
        mode === ShowMode.AllClass && id >= userClassIDmin
          ? "userAbstract"
          : "abstract"
      }
      boxShadow={selected ? "outline" : "none"}
    >
      <ClassProps isOpen={isOpen} onClose={onClose} data={data} />
      <Handle
        isConnectable={false}
        type="target"
        position={Position.Left}
        style={{
          width: "10px",
          height: "20px",
          borderRadius: "3px",
          border: "1px solid black",
          backgroundColor: bg,
        }}
      />
      <Stack direction="row" alignItems="center">
        <Text>{data.item.name}</Text>
        <Tooltip label="Свойства">
          <IconButton onClick={onOpen} aria-label="properties">
            <SettingsIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <Handle
        isConnectable={false}
        type="source"
        position={Position.Right}
        style={{
          width: "10px",
          height: "20px",
          borderRadius: "3px",
          border: "1px solid black",
          backgroundColor: bg,
        }}
      />
    </Block>
  );
}
