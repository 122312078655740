import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  IconButton,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Properties } from "promuc-table";
import { Handle, Position } from "react-flow-renderer";
import { IoListOutline } from "react-icons/io5";
import { darkEdgesColor, lightEdgesColor } from "../../../styles/colors";
import { ShowMode, userClassIDmin } from "../../../utils/constants";
import useStore from "../../../utils/store";
import ClassInsts from "../modals/ClassInsts";
import Block from "./ChakraNode";

export type Class = {
  data: {
    item: any;
    props: any;
  };
  selected: boolean;
};

export default function LinkedNode(props: Class) {
  const { data, selected } = props;

  const {
    isOpen: isSubOpen,
    onOpen: onSubOpen,
    onClose: onSubClose,
  } = useDisclosure(); // for modal window with instances

  const bg = useColorModeValue(lightEdgesColor, darkEdgesColor);

  const id = data.item.id;
  const type = data.item.is_abstract ? "abstract" : "real";
  const mode = useStore((state) => state.showMode);

  return (
    <Accordion allowToggle>
      <AccordionItem
        style={{
          border: "none",
        }}
      >
        {({ isExpanded }) => (
          <>
            <Block
              style={{
                transition: "all 0.2s ease",
                borderRadius: isExpanded ? "15px 15px 0 0" : "15px",
              }}
              variant={
                id >= userClassIDmin && mode === ShowMode.AllClass
                  ? `user${type.charAt(0).toUpperCase() + type.slice(1)}`
                  : type
              }
              boxShadow={selected ? "outline" : "none"}
            >
              <AccordionButton
                style={{
                  padding: 0,
                }}
              >
                <ClassInsts
                  isOpen={isSubOpen}
                  onClose={onSubClose}
                  data={data}
                />

                {/* Header */}
                <Stack direction="row" alignItems="center">
                  <Handle
                    isConnectable={false}
                    type="target"
                    id="main"
                    position={Position.Left}
                    style={{
                      width: "10px",
                      height: "20px",
                      borderRadius: "3px",
                      border: "1px solid black",
                      backgroundColor: bg,
                    }}
                  />
                  <Text>{data.item.name}</Text>

                  <Tooltip label="Объекты">
                    <IconButton onClick={onSubOpen} aria-label="instances">
                      <IoListOutline />
                    </IconButton>
                  </Tooltip>
                  <AccordionIcon />
                  <Handle
                    isConnectable={false}
                    type="source"
                    id="main"
                    position={Position.Right}
                    style={{
                      width: "10px",
                      height: "20px",
                      borderRadius: "3px",
                      border: "1px solid black",
                      backgroundColor: bg,
                    }}
                  />
                </Stack>
              </AccordionButton>
            </Block>

            {/* Body */}
            <Block
              style={{
                transition: "all 0.2s ease",
                borderRadius: isExpanded ? "0 0 15px 15px" : "15px",
                padding: 0,
                height: "auto",
                borderTop: "none",
              }}
              variant={
                id >= userClassIDmin && mode === ShowMode.AllClass
                  ? `user${type.charAt(0).toUpperCase() + type.slice(1)}`
                  : type
              }
            >
              <AccordionPanel>
                <Stack>
                  {data.props.map((prop: Properties, index: number) => {
                    return (
                      <Box>
                        <Handle
                          isConnectable={false}
                          type="target"
                          id={`${prop.id}`}
                          position={Position.Left}
                          style={{
                            width: "10px",
                            height: "20px",
                            top: `${index * 32 + 66}px`,
                            borderRadius: "3px",
                            border: "1px solid black",
                            backgroundColor: bg,
                          }}
                        />
                        <Text>{prop.name}</Text>
                        <Handle
                          isConnectable={false}
                          type="source"
                          id={`${prop.id}`}
                          position={Position.Right}
                          style={{
                            width: "10px",
                            height: "20px",
                            top: `${index * 32 + 66}px`,
                            borderRadius: "3px",
                            border: "1px solid black",
                            backgroundColor: bg,
                          }}
                        />
                      </Box>
                    );
                  })}
                </Stack>
              </AccordionPanel>
            </Block>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
}
