import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { ForwardedRef, forwardRef, useState } from "react";

type EditableTableProps = {
  entities: any[] | undefined;
  properties: any[] | undefined;
  startRef?: React.MutableRefObject<any>;
  onChoose: (item: any) => void;
};

type RowProps = {
  entity: any | undefined;
  properties: any[] | undefined;
  active: number;
  index: number;
  startRef?: React.MutableRefObject<any>;
  onClick: () => void;
};

const Row = forwardRef((props: RowProps, ref: ForwardedRef<any>) => {
  const { entity, properties, index, active, startRef, onClick } = props;

  return (
    <Tr
      onClick={onClick}
      bgColor={active === index ? "blue.600" : "inherit"}
      transition="all 0.15s ease"
      ref={startRef ? startRef : ref}
    >
      {properties?.map((col) => {
        let res = entity[col.mnemo]?.toString();
        // Meta говорит, что это подвержено XSS-атакам
        if (res && res.startsWith("<svg")) {
          return <Td dangerouslySetInnerHTML={{ __html: res }}></Td>;
        }
        return <Td>{res}</Td>;
      })}
    </Tr>
  );
});

const TableWithState = forwardRef(
  (props: EditableTableProps, ref: ForwardedRef<unknown>) => {
    const {
      entities, // это, собственно, само множество элементов, которое надо редактировать
      properties, // а это множество свойств класса, по которым мы разбираем множество свойств на таблицу
      startRef,
      onChoose,
    } = props;

    const [active, setActive] = useState(-1);

    return (
      <Table colorScheme="blue">
        <Thead position={"sticky"} top={0} backdropFilter={"blur(10px)"}>
          <Tr>
            {properties?.map((prop: any) => (
              <Th>{`${prop.name} (${prop.mnemo})`}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {entities?.map((ent: any, index: number) => (
            <Row
              entity={ent}
              properties={properties}
              active={active}
              index={index}
              onClick={() => {
                setActive(index);
                onChoose(ent);
              }}
              startRef={index === 0? startRef : undefined}
              ref={index === entities.length - 1 ? ref : undefined}
            />
          ))}
        </Tbody>
      </Table>
    );
  }
);
export default TableWithState;
