import { tableAnatomy } from "@chakra-ui/anatomy";

import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = definePartsStyle({
  table: {
    borderCollapse: "separate"
  },
  // define the part you're going to style
  th: {
    _notLast: {
      borderRightWidth: 1,
      // borderBottomWidth: 1,
    },
  },
  td: {
    _notLast: {
      borderRightWidth: 1,
      // borderBottomWidth: 1,
    },
  },
});

export const tableTheme = defineMultiStyleConfig({ baseStyle });
