import { useEffect } from "react";
import {
  Box,
  Center,
  Spinner,
  Stack,
  useBoolean,
  useDisclosure,
} from "@chakra-ui/react";
import DataWorker from "promuc-table";
import LeftSidebar from "./containers/leftSidebar/LeftSidebar";
import FlowBox from "./containers/mainWindow/FlowBox";
import useStore from "./utils/store";
import NewClassModal from "./containers/leftSidebar/NewClassModal";
import ConfirmDelete from "./containers/mainWindow/modals/ConfirmDelete";
import { useEdgesState, useNodesState } from "react-flow-renderer";

const App = () => {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const [nodes, setNodes, onNodesChange] = useNodesState<any>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<any>([]);

  const {
    isOpen: isMakingNew,
    onOpen: onNewClick,
    onClose: makeNewClose,
  } = useDisclosure();
  const {
    isOpen: isDelete,
    onOpen: onDeleteClick,
    onClose: makeDeleteClose,
  } = useDisclosure();

  const [isLoading, setIsLoading] = useBoolean(true);
  const server = useStore((state) => state.server);
  const setStorage = useStore((state) => state.setStorage);
  const outerStorage = useStore((state) => state.outerStorage);

  /**
   *  Получаем первоначальные данные о системных сущностях и пользовательских сущностях
   */
  useEffect(() => {
    console.log("First render...");
    setIsLoading.on();
    DataWorker.init(server).then((storage) => {
      setStorage(storage);
      setIsLoading.off();
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box w="100vw" h="100vh">
      {isLoading ? (
        <Center h="100vh">
          <Stack align="center" spacing={5}>
            <Spinner size="lg" />
          </Stack>
        </Center>
      ) : (
        <>
          <NewClassModal
            isOpen={isMakingNew}
            onClose={makeNewClose}
            setNodes={setNodes}
            setEdges={setEdges}
          />
          <ConfirmDelete
            isOpen={isDelete}
            onClose={makeDeleteClose}
            deleteItem={() => {
              const toDelete = nodes.filter((item) => item.selected === true);
              toDelete.forEach((item) => {
                outerStorage
                  ?.deleteData( item.data.item.id )
                  .then((data) => {
                    const newNodes = nodes.filter((item) => {
                      if (data.includes(item.data.item.id)) {
                        return false;
                      }
                      return true;
                    });
                    setNodes(newNodes);
                  });
              });
            }}
          />
          <LeftSidebar
            isOpen={isOpen} // для того чтобы по дефолту был открыт
            onClose={onClose}
            onNewClick={onNewClick}
            onDeleteClick={onDeleteClick}
          />
          <FlowBox
            onOpen={onOpen}
            nodes={nodes}
            setNodes={setNodes}
            onNodesChange={onNodesChange}
            edges={edges}
            setEdges={setEdges}
            onEdgesChange={onEdgesChange}
          />
        </>
      )}
    </Box>
  );
};

export default App;
