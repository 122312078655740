import { Box, BoxProps, useStyleConfig } from "@chakra-ui/react";

interface BlockProps extends BoxProps {
  variant: string;
}

function Block(props: BlockProps) {
  const { variant, ...rest } = props;

  const styles = useStyleConfig("Block", { variant });

  // Pass the computed styles into the `__css` prop
  return <Box __css={styles} {...rest} />
}

export default Block;
