import { useState } from "react";
import {
  Button,
  ButtonProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";

import { MdKeyboardArrowDown } from "react-icons/md";

type MultiSelectProps = {
  label: string;
  options: { name: string; id: string }[];
  startOptions?: string[];
  onChange?: (selectedValues: string[]) => void;
  buttonProps?: ButtonProps;
  disabled?: boolean;
};

MultiSelect.displayName = "MultiSelect";

export default function MultiSelect(props: MultiSelectProps): JSX.Element {
  const { label, options, startOptions, buttonProps, disabled = false } = props;
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    startOptions ? startOptions : []
  );

  return (
    <Menu closeOnSelect={false} isLazy>
      {({ onClose }) => (
        <>
          <MenuButton
            as={Button}
            rightIcon={<MdKeyboardArrowDown />}
            {...buttonProps}
            disabled={disabled}
          >
            {`${label}${
              selectedOptions.length > 0 ? ` (${selectedOptions.length})` : ""
            }`}
          </MenuButton>
          <MenuList maxH={250} overflowY="scroll">
            <MenuGroup title={undefined}>
              <MenuItem
                onClick={() => {
                  setSelectedOptions([]);
                  props.onChange?.([]);
                  onClose();
                }}
              >
                Убрать все
              </MenuItem>
            </MenuGroup>
            <MenuDivider />
            <MenuOptionGroup
              title={undefined}
              defaultValue={selectedOptions}
              type="checkbox"
              //@ts-ignores
              onChange={(values: string[]) => {
                setSelectedOptions(values);
                props.onChange?.(values);
              }}
            >
              {options.map((option) => {
                return (
                  <MenuItemOption
                    key={`multiselect-${option.id}`}
                    value={option.id}
                  >
                    {option.name}
                  </MenuItemOption>
                );
              })}
            </MenuOptionGroup>
          </MenuList>
        </>
      )}
    </Menu>
  );
}
