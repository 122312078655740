import { useRef } from "react";
import { createContext } from "react";
import AuthService from "../../utils/authService";
import useStore from "../../utils/store";

const AuthContext = createContext({
  logout: () => {},
  signinSilent : () => {},
  isAuthenticated: () => ({}),
  login: () => {},
  loginCallback: () => {}
});

export const AuthConsumer = AuthContext.Consumer;

export const AuthProvider = (props: any) => {
  const setUser = useStore((state) => state.setUser);
  const auth = useRef(new AuthService(setUser));

  return (
    <AuthContext.Provider value={auth.current}>{props.children}</AuthContext.Provider>
  );
};
