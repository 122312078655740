// тип для отображения, чтобы не писать вручную
export const enum ShowMode {
  SystemClass = "SYS",
  UserClass = "USER",
  AllClass= "ALL",
}

export const enum View {
  Hierarcy = "EXT",
  Relation = "REL"
}

let link;
let clLink;
let clId;
let secret;
let authority;

if (process.env.NODE_ENV === "production") {
  link = window.location.host;
  clLink = `https://${link}`;
  clId = "82f0a26b-27c1-4373-b129-9acc339124ea";
  secret = "gto_ds6r7xg35cpxsmb4khdgu6s5ylhbfil7z7hcn4onttz7innre6rq";
  authority = "https://git.4iot.pro";
} else {
  link = "localhost:8000";
  clLink = "https://localhost:3000";
  clId = "3963546f-2626-4cb8-ae99-0d1a0abe2e20";
  secret = "gto_5hbsouf2duilnwugcf53uaujj4qaabpyrclqsgngmjdn5633egxa";
  authority = "https://localhost:8000";
}

const tokenUrl = `${authority}/login/oauth/access_token`;
const userInfo = `${authority}/login/oauth/userinfo`;

export const userInfoURL = `${authority}/api/v1/user`;

export const currentLink = link;
export const clientLink = clLink;

export const userClassIDmin = 20000000;
export const classID = 10000001;
export const objID = 10000000;
export const fieldID = 10000005;
export const linkID = 10000006;
export const linkTypeID = 10000021;

export const IDENTITY_CONFIG = {
  authority: "https://git.4iot.pro",
  client_id: clId,
  client_secret: secret,
  redirect_uri: `${clientLink}`,
  login: `https://git.4iot.pro/login/oauth/authorize`,
  automaticSilentRenew: true,
  loadUserInfo: true,
  responseType: "id_token",
  grantType: "authorization_code",
  scope: "openid",
  webAuthResponseType: "id_token token",
};

export const METADATA_OIDC = {
  end_session_endpoint: clientLink,
  issuer: "https://git.4iot.pro",
  authorization_endpoint: `https://git.4iot.pro/login/oauth/authorize`,
  token_endpoint: tokenUrl,
  jwks_uri: `https://git.4iot.pro/login/oauth/keys`,
  userinfo_endpoint: userInfo,
  introspection_endpoint: `https://git.4iot.pro/login/oauth/introspect`,
};
