import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { currentLink, ShowMode, View } from "./constants";
import DataWorker from "promuc-table";

// Можно было бы сделать нормальные типы для classes и другого, но типы не фиксированные и могут постоянно меняться
interface AppState {
  server: string;
  showMode: ShowMode;
  view: View;
  outerStorage: DataWorker | undefined;
  user: any;
  changeShowMode(toNewView: ShowMode): void;
  changeView(toNewView: View): void;
  setStorage(storage: DataWorker): void;
  setUser(user: any): void;
}

const useStore = create<AppState>()(
  persist(
    (set, get) => ({
      server: (function () {
        let dev = localStorage.getItem("dev");
        if (dev !== null) {
          console.log(dev);
          return dev;
        }
        console.log(`https://${currentLink}/api/`)
        return `https://${currentLink}/api/`;
      })(),
      showMode: (function () {
        if (get()) {
          return get().user.is_admin
            ? ShowMode.SystemClass
            : ShowMode.UserClass;
        }
        return ShowMode.UserClass;
      })(),
      view: View.Hierarcy,
      outerStorage: undefined,
      user: {
        is_admin: true,
        username: "testWithoutAuth",
      },
      changeShowMode: (toNewView: ShowMode) =>
        set(() => ({ showMode: toNewView })),
      changeView: (toNewView: View) => set(() => ({ view: toNewView })),
      setStorage: (storage: DataWorker) =>
        set(() => ({ outerStorage: storage })),
      setUser: (newUser: any) => set(() => ({ user: newUser })),
    }),
    {
      name: "AppState",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default useStore;
