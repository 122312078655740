import { HamburgerIcon } from "@chakra-ui/icons";
import { useColorModeValue } from "@chakra-ui/react";
import React, { useCallback, useEffect } from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  ControlButton,
  Background,
  BackgroundVariant,
  addEdge,
  Connection,
  Node,
  Edge,
  MarkerType,
  OnNodesChange,
  OnEdgesChange,
} from "react-flow-renderer";
import { Classes } from "promuc-table";
import {
  darkBackgroundColor,
  darkDotsColor,
  lightBackgroundColor,
  lightDotsColor,
} from "../../styles/colors";
import { userClassIDmin, ShowMode, View } from "../../utils/constants";
import useStore from "../../utils/store";
import AbstractNode from "./nodes/AbstractNode";
import ClassNode from "./nodes/ClassNode";
import { findHierarcyDepth, findRelationDepth } from "../../utils/functions";
import LinkedNode from "./nodes/LinkedNode";

const nodeTypes = {
  class: ClassNode,
  abstract: AbstractNode,
  linked: LinkedNode,
};
const edgeOptions = {
  style: {
    strokeWidth: 1,
    cursor: "pointer",
  },
};

function FlowBox({
  onOpen,
  nodes,
  setNodes,
  onNodesChange,
  edges,
  setEdges,
  onEdgesChange,
}: {
  onOpen: () => void;
  nodes: Node[];
  setNodes: React.Dispatch<React.SetStateAction<Node<any>[]>>;
  onNodesChange: OnNodesChange;
  edges: Edge[];
  setEdges: React.Dispatch<React.SetStateAction<Edge<any>[]>>;
  onEdgesChange: OnEdgesChange;
}) {
  const currentMode = useStore((state) => state.showMode);
  const currentView = useStore((state) => state.view);

  const outerStorage = useStore((state) => state.outerStorage);

  const bg = useColorModeValue(lightBackgroundColor, darkBackgroundColor); // Задний фон
  const color = useColorModeValue(lightDotsColor, darkDotsColor); // цвет точек

  useEffect(() => {
    console.log("rerendering nodes...");
    const allClasses = outerStorage?.classes;
    const classes: Classes[] = [];

    switch (currentMode) {
      case ShowMode.SystemClass: {
        allClasses?.forEach((item) => {
          if (item.id < userClassIDmin) {
            classes.push(item);
          }
        });
        break;
      }
      case ShowMode.UserClass: {
        allClasses?.forEach((item) => {
          if (item.id >= userClassIDmin) {
            classes.push(item);
          }
        });
        break;
      }
      case ShowMode.AllClass: {
        allClasses?.forEach((item) => {
          classes.push(item);
        });
        break;
      }
      default: {
        console.warn("Unexpected view: " + currentMode);
        break;
      }
    }

    const collection: any[] = [];
    const depths: {
      level: number;
      count: number;
    }[] = [];
    classes.forEach((item: Classes) => {
      const props = outerStorage?.getFullClassInfo(item.id);
      let depth: number;
      let height: number = 0;
      if (currentView === View.Hierarcy) {
        // чем больше уровень - тем правее
        depth = findHierarcyDepth(item, allClasses, 0);
      } else {
        // чем больше уровень - тем левее
        depth = findRelationDepth(item.id, outerStorage);
      }
      const needed = depths.find((item) => item.level === depth);
      if (needed) {
        needed.count += 1;
        height = needed.count;
      } else {
        depths.push({
          level: depth,
          count: 0,
        });
      }
      collection.push({
        item,
        props,
        depth,
        height,
      });
    });

    const newNodes: Node<any>[] = [];
    const newEdges: Edge<any>[] = [];
    switch (currentView) {
      case View.Hierarcy:
        collection.forEach((item: any, index: number) => {
          // Добавление связей
          item?.item?.extends?.forEach((parent: any) => {
            newEdges.push({
              id: `eds${parent}_${item.item.id}`,
              source: parent?.toString(),
              target: item?.item?.id?.toString(),
              type: "smoothstep",
              markerEnd: {
                type: MarkerType.ArrowClosed,
              },
            });
          });
          // Добавление узлов
          newNodes.push({
            id: item?.item?.id?.toString(),
            type: item?.item?.is_abstract ? "abstract" : "class",
            connectable: false, // пока запрет на создание связей, надо убрать как только будет сделано через reactFlow
            data: {
              item: item.item, // запоминаем основные хар-ки класса
              props: item.props, // а также  его свойства: поля, связи и все такое
            },
            position: {
              x: item.depth * 300,
              y: item.height * 75,
            },
          });
        });
        break;
      case View.Relation:
        collection?.forEach((item: any, index, array: any) => {
          // Добавление связей
          item?.props?.forEach((prop: any) => {
            if (prop.type.mnemo === "link") {
              // связь, показывающая по какому полю класс А связан с классом Б
              newEdges.push({
                id: `eds${item.item.id}:${prop.id}_${prop.link_class_id}:${0}`,
                source: item?.item?.id?.toString(),
                sourceHandle: prop?.id?.toString(),
                target: prop?.link_class_id?.toString(),
                targetHandle: "0",
                type: "simplebezier",
                zIndex: 1001,
              });
              // связь, отображающая, что у класса А в принципе есть связь с классом Б
              newEdges.push({
                id: `eds${item.item.id}:main_${prop.link_class_id}:main`,
                source: item?.item?.id?.toString(),
                sourceHandle: "main",
                target: prop?.link_class_id?.toString(),
                targetHandle: "main",
                type: "simplebezier",
                zIndex: 1001,
              });
            }
          });

          // Добавление узлов
          newNodes.push({
            id: item?.item?.id?.toString(),
            type: "linked",
            connectable: false, // пока запрет на создание связей, надо убрать как только будет сделано через reactFlow
            data: {
              item: item.item, // запоминаем основные хар-ки класса
              props: item.props, // а также  его свойства: поля, связи и все такое
            },
            position: {
              x: -item.depth * 300,
              y: item.height * 100,
            },
          });
        });

        break;
      default:
        break;
    }
    setNodes(newNodes);
    setEdges(newEdges);
  }, [currentMode, currentView, outerStorage, setEdges, setNodes]);

  const onConnect = useCallback(
    (connection: Connection) => {
      setEdges((eds) =>
        addEdge(
          {
            ...connection,
            type: "smoothstep",
            markerEnd: {
              type: MarkerType.ArrowClosed,
            },
          },
          eds
        )
      );
    },
    [setEdges]
  );

  return (
    <ReactFlow
      defaultNodes={nodes}
      defaultEdges={edges}
      defaultEdgeOptions={edgeOptions}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodeTypes={nodeTypes}
      onConnect={onConnect}
      fitView
      attributionPosition="bottom-left"
    >
      <Background
        variant={BackgroundVariant.Dots}
        color={color}
        style={{ backgroundColor: bg }}
        gap={25}
      />
      <MiniMap style={{ backgroundColor: bg }} />
      <Controls
        style={{
          bottom: "auto",
          left: "auto",
          top: "20px",
          right: "15px",
        }}
      >
        <ControlButton onClick={onOpen}>
          <HamburgerIcon color="black" />
        </ControlButton>
      </Controls>
    </ReactFlow>
  );
}

export default FlowBox;
