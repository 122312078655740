import {
  InfoIcon,
  MinusIcon,
  MoonIcon,
  SunIcon,
  UpDownIcon,
} from "@chakra-ui/icons";
import {
  ButtonGroup,
  Stack,
  IconButton,
  Image,
  useColorMode,
  Heading,
  Tooltip,
} from "@chakra-ui/react";
import { useState } from "react";
import logo from "../../images/promuc_blue_logo.svg";
import UserInfo from "./UserInfo";

interface FullScreenDocumentElement extends HTMLElement {
  msRequestFullscreen?: () => void;
  mozRequestFullScreen?: () => void;
  webkitRequestFullscreen?: () => void;
}

interface FullScreenDocument extends Document {
  documentElement: FullScreenDocumentElement;
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  webkitFullscreenElement?: Element;
  msExitFullscreen?: () => void;
  mozCancelFullScreen?: () => void;
  webkitExitFullscreen?: () => void;
}

export default function SidebarHeader() {
  const { colorMode, toggleColorMode } = useColorMode();
  const [fullScreen, setFullScreen] = useState(false);

  const doc = document as FullScreenDocument;

  const openFullScreen = () => {
    const elem = doc.documentElement;
    setFullScreen(true);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  const closeFullScreen = () => {
    setFullScreen(false);
    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } else if (doc.mozCancelFullScreen) {
      doc.mozCancelFullScreen();
    } else if (doc.webkitExitFullscreen) {
      doc.webkitExitFullscreen();
    } else if (doc.msExitFullscreen) {
      doc.msExitFullscreen();
    }
  };

  return (
    <Stack>
      <Image height="64px" src={logo} alt="PromUC Framework" />
      <ButtonGroup
        alignItems="center"
        justifyContent="center"
        display="flex"
        my={2.5}
      >
        {fullScreen ? (
          <Tooltip label="Выйти из полноэкранного режима">
            <IconButton
              variant="ghost"
              icon={<MinusIcon />}
              onClick={closeFullScreen}
              aria-label={""}
            />
          </Tooltip>
        ) : (
          <Tooltip label="Войти в полноэкранный режим">
            <IconButton
              variant="ghost"
              icon={<UpDownIcon />}
              onClick={openFullScreen}
              aria-label={""}
            />
          </Tooltip>
        )}
        <Tooltip label="Переключить тему">
          <IconButton
            variant="ghost"
            icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            onClick={toggleColorMode}
            aria-label={""}
          />
        </Tooltip>
        <Tooltip label="Помощь">
          <IconButton variant="ghost" icon={<InfoIcon />} aria-label={""} />
        </Tooltip>
        <UserInfo />
      </ButtonGroup>
      <Heading>Framework DB</Heading>
    </Stack>
  );
}
