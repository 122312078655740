import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  deleteItem: () => void;
};

export default function ConfirmDelete(props: ModalProps) {
  const { isOpen, onClose, deleteItem } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Удалить выбранное?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Это действие невозможно отменить</ModalBody>
        <ModalFooter>
          <Button onClick={onClose} mr={3}>
            Отмена
          </Button>
          <Button
            onClick={() => {
              deleteItem();
              onClose();
            }}
          >
            Удалить
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
