import ReactDOM from "react-dom/client";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import App from "./App";
import { ReactFlowProvider } from "react-flow-renderer";
import theme from "./styles/apllicationTheme";
import "@fontsource/nunito/700.css";
import "@fontsource/open-sans/400.css";
// import { AuthProvider } from "./components/auth/AuthProvider";
// import PrivateRoute from "./components/auth/PrivateRoute";
// import StartPage from "./StartPage";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  // <AuthProvider>
    <ChakraProvider theme={theme}>
      <ReactFlowProvider>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        {/* <PrivateRoute fallback={<StartPage />}> */}
          <App />
        {/* </PrivateRoute> */}
      </ReactFlowProvider>
    </ChakraProvider>
  // </AuthProvider>
);
