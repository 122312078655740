import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  Icon,
  Tooltip,
  Image,
} from "@chakra-ui/react";
import { IoExitSharp } from "react-icons/io5";
import useStore from "../../utils/store";
import giteaLogo from "../../images/gitea-icon.svg";
import { AuthConsumer } from "../../components/auth/AuthProvider";

export default function UserInfo() {
  const user = useStore((state) => state.user);

  return (
    <AuthConsumer>
      {({logout}) => (
        <Menu>
          {({ isOpen }) => (
            <>
              <Tooltip label="Профиль и настройки">
                <MenuButton
                  as={Button}
                  variant={"ghost"}
                  leftIcon={
                    <Avatar
                      size="sm"
                      name={user.username}
                      src={user.avatar_url}
                    ></Avatar>
                  }
                  rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                />
              </Tooltip>
              <MenuList>
                <MenuGroup title={`Вы вошли как ${user.username}`}>
                  <MenuItem
                    icon={<Image h={3} src={giteaLogo} alt="Gitea" />}
                    onClick={() => {
                      window.open("https://git.4iot.pro");
                    }}
                  >
                    <Text>Перейти в Gitea</Text>
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem icon={<Icon as={IoExitSharp} />} onClick={() => {
                    logout();
                  }}>
                    <Text size={"xs"}>Выйти</Text>
                  </MenuItem>
                </MenuGroup>
              </MenuList>
            </>
          )}
        </Menu>
      )}
    </AuthConsumer>
  );
}
