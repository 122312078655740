import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Edge, MarkerType, Node } from "react-flow-renderer";
import DynamicForm from "../../components/DynamicForm";
import { classID, objID, userClassIDmin } from "../../utils/constants";
import { findHierarcyDepth, makeNewEntity } from "../../utils/functions";
import useStore from "../../utils/store";

type NewClassModalProps = {
  isOpen: boolean;
  onClose: () => void;
  setNodes: React.Dispatch<React.SetStateAction<Node<any>[]>>;
  setEdges: React.Dispatch<React.SetStateAction<Edge<any>[]>>;
};

export default function NewClassModal(props: NewClassModalProps) {
  const { isOpen, onClose, setNodes, setEdges } = props;

  const [newItem, setNewItem] = useState<any>({});
  const toast = useToast();

  const outerStorage = useStore((state) => state.outerStorage);
  const classProps = outerStorage?.getFullClassInfo(classID)?.filter((i) => {
    return i.mnemo !== "owner_id" && i.mnemo !== "id";
  });

  const required: string[] = [];
  const booleans: string[] = [];
  const numbers: string[] = [];

  function makeNewClass() {
    makeNewEntity(
      numbers,
      booleans,
      required,
      newItem,
      (item) => {
        item["owner_id"] = classID;
        if (!item["extends"]) {
          item["extends"] = [objID];
        } else {
          if (!item["extends"].includes(objID)) {
            item["extends"].unshift(objID);
          }
        }
        return item;
      },
      () => {
        console.log(newItem);
        outerStorage?.putData(newItem).then((data: any) => {
          const props = outerStorage.getFullClassInfo(data.id);
          const allClasses = outerStorage.getByQueryList({
            cls: classID,
            isFull: true,
            filters: [
              `owner_id = ${classID}`,
              `id >= ${userClassIDmin}`
            ]
          })
          const depth = findHierarcyDepth(data, allClasses, 0);
          setNodes((oldNodes) => [
            ...oldNodes,
            {
              id: data.id?.toString(),
              type: data.is_abstract ? "abstract" : "class",
              connectable: false,
              data: {
                item: data,
                props: props,
              },
              position: { x: depth * 300, y: oldNodes.length * 75 },
            },
          ]);
          data.extends.forEach((parent: number) => {
            setEdges((oldEdges) => [
              ...oldEdges,
              {
                id: `eds${parent}_${data.id}`,
                source: parent?.toString(),
                target: data.id?.toString(),
                type: "smoothstep",
                markerEnd: {
                  type: MarkerType.ArrowClosed,
                },
              }
            ])
          })
          console.log(data, "should be saved at TableWorker");
          console.log("saved!");
          toast({
            title: "Сохранено",
            description: "Класс создан и успешно сохранен",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onClose();
        });
      }
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalContent>
        <ModalHeader>Добавление нового класса</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <DynamicForm
            classProps={classProps}
            required={required}
            booleans={booleans}
            numbers={numbers}
            item={newItem}
            setItem={setNewItem}
          />
          <Center>
            <Button variant="outline" onClick={makeNewClass}>
              Создать класс
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
