import { useEffect, useState } from "react";
import useStore from "./store";

type HookProps = {
  table: number | string;
  pageNumber?: number;
  pageCapacity?: number;
  isOpened: boolean;
};

export default function useInfScroll(props: HookProps) {
  const { table, pageNumber = 1, pageCapacity = 10, isOpened } = props;
  const store = useStore((store) => store.outerStorage);
  const [result, setResult] = useState<object[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState("");
  const [hasNextPage, setHasNextPage] = useState(false);

  useEffect(() => {
    if (isOpened) {
      setIsLoading(true);
      setHasError(false);
      setError("");

      const controller = new AbortController();
      const { signal } = controller;
      store
        ?.getByQueryList({
          cls: table,
          isFull: true,
          filters: [`owner_id = ${table}`],
          howMuch: pageCapacity,
          startFrom: pageNumber * pageCapacity,
        })
        .then((data) => {
          // на случай если поменялся pageCapacity, но не pageNumber в нуле
          if (pageNumber === 0) {
            setResult([...data]);
          } else {
            setResult((prev) => [...prev, ...data]);
          }
          setHasNextPage(Boolean(data.length));
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (signal.aborted) return;
          setHasError(true);
          setError(error.message);
        });

      return () => {
        controller.abort();
      };
    }
  }, [table, pageNumber, store, pageCapacity, isOpened]);

  return { isLoading, hasError, error, result, hasNextPage };
}
