import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  IoAddSharp,
  IoArrowForwardOutline,
  IoTrashBinSharp,
} from "react-icons/io5";
import {
  darkAbstractNodesColor,
  darkBackgroundColor,
  darkNodesColor,
  lightAbstractNodesColor,
  lightBackgroundColor,
  lightNodesColor,
} from "../../styles/colors";
import useStore from "../../utils/store";
import { ShowMode, View } from "../../utils/constants";
import SidebarHeader from "./SidebarHeader";
import { useReactFlow } from "react-flow-renderer";

type LeftSidebarProps = {
  isOpen: boolean;
  onClose: () => void;
  onNewClick: () => void;
  onDeleteClick: () => void;
};

export default function LeftSidebar(props: LeftSidebarProps) {
  const flowStore = useReactFlow();
  const isSelected =
    flowStore.getNodes().filter((item) => item.selected === true).length > 0;

  const { isOpen, onClose, onNewClick, onDeleteClick } = props;

  const user = useStore((state) => state.user);

  // Для легенды
  const bg = useColorModeValue(lightNodesColor, darkNodesColor);
  const absBg = useColorModeValue(
    lightAbstractNodesColor,
    darkAbstractNodesColor
  );

  const bgf = useColorModeValue(lightBackgroundColor, darkBackgroundColor); // Задний фон

  const uBg = `repeating-linear(45deg, ${bg},  ${bg} 5px, ${bgf} 5px, ${bgf} 10px)`;
  const uAbsBg = `repeating-linear(45deg, ${absBg},  ${absBg} 5px, ${bgf} 5px, ${bgf} 10px)`;

  // zustand store
  const currentShomMode = useStore((state) => state.showMode);
  const changeShowMode = useStore((state) => state.changeShowMode);

  const currentView = useStore((state) => state.view);
  const changeView = useStore((state) => state.changeView);

  return (
    <Drawer
      isOpen={isOpen}
      placement="left"
      onClose={onClose}
      preserveScrollBarGap
      variant={"alwaysOpen"}
    >
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <SidebarHeader />
        </DrawerHeader>
        <DrawerBody>
          <Stack spacing={4}>
            <Heading>Вид</Heading>
            <RadioGroup defaultValue={currentView} onChange={changeView}>
              <Stack spacing={1}>
                <Radio value={View.Hierarcy}>Иерархия наследования</Radio>
                <Radio value={View.Relation}>Связи между сущностями</Radio>
              </Stack>
            </RadioGroup>

            <Heading>Отображать</Heading>
            <RadioGroup
              defaultValue={currentShomMode}
              onChange={changeShowMode}
            >
              <Stack spacing={1}>
                {user.is_admin && (
                  <>
                    <Radio value={ShowMode.AllClass}>Все классы</Radio>
                    <Radio value={ShowMode.SystemClass}>
                      Только системные классы
                    </Radio>
                  </>
                )}
                <Radio value={ShowMode.UserClass}>
                  Только пользовательские классы
                </Radio>
              </Stack>
            </RadioGroup>

            <Stack spacing={1}>
              <Heading>Легенда</Heading>
              <Stack direction="row" spacing={1}>
                <Box boxSize={5} bg={bg} />
                <Text> - Класс</Text>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Box boxSize={5} bg={absBg} />
                <Text> - Абстрактный класс</Text>
              </Stack>
              {!(
                currentShomMode === ShowMode.UserClass ||
                currentShomMode === ShowMode.SystemClass
              ) && (
                <>
                  <Stack direction="row" spacing={1}>
                    <Box boxSize={5} bgGradient={uBg} />
                    <Text> - Пользовательский класс</Text>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Box boxSize={5} bgGradient={uAbsBg} />
                    <Text> - Пользовательский абстрактный класс</Text>
                  </Stack>
                </>
              )}
              {currentView === View.Hierarcy && (
                <Stack direction="row" spacing={1}>
                  <Text>Предок</Text>
                  <Box boxSize={5}>
                    <IoArrowForwardOutline />
                  </Box>
                  <Text>Потомок</Text>
                </Stack>
              )}
              <Heading>Действия</Heading>
              <Button leftIcon={<IoAddSharp />} onClick={onNewClick}>
                Новый класс
              </Button>
              <Button
                disabled={!isSelected}
                leftIcon={<IoTrashBinSharp />}
                onClick={onDeleteClick}
              >
                Удалить классы
              </Button>
            </Stack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
