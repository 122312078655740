import { EditIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  Collapse,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Properties } from "promuc-table";
import { useState } from "react";
import { useRef } from "react";
import { useCallback } from "react";
import { IoBanSharp, IoSaveSharp } from "react-icons/io5";
import DynamicForm from "../../../components/DynamicForm";
import TableWithState from "../../../components/TableWithState";
import useStore from "../../../utils/store";
import useInfScroll from "../../../utils/useInfScroll";
import { makeNewEntity } from "../../../utils/functions";
import { ButtonsAndModal } from "./ClassProps";

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  data: {
    item: any;
    props: any;
  };
};

export default function ClassInsts(props: ModalProps) {
  const { isOpen, onClose, data } = props;

  const {
    isOpen: isDelete,
    onOpen: onDelete,
    onClose: onCancel,
  } = useDisclosure();

  const [edit, setEdit] = useState(false);
  const [make, setMake] = useState(false);
  const [error, setError] = useState(false);

  const outerStorage = useStore((state) => state.outerStorage);

  const info = {
    numbers: [],
    booleans: [],
    required: [],
  };

  const [active, setActive] = useState({});
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const startRef = useRef<any>();
  const endRef = useRef<any>();

  const { isLoading, hasError, result, hasNextPage } = useInfScroll({
    table: data.item.id,
    pageNumber: currentPage,
    pageCapacity: itemsPerPage,
    isOpened: isOpen
  });

  const lastObserver = useRef<IntersectionObserver>();
  const toast = useToast();

  const propsForItem = data.props.filter((i: any) => {
    return i.mnemo !== "owner_id" && i.mnemo !== "id";
  });

  const lastItem = useCallback(
    (item: any) => {
      if (isLoading) return;
      if (lastObserver.current) lastObserver.current.disconnect();

      lastObserver.current = new IntersectionObserver(([items]) => {
        if (items.isIntersecting && hasNextPage && !hasError) {
          console.warn("near last");
          setCurrentPage((prev) => prev + 1);
        }
      });

      if (item) {
        endRef.current = item
        lastObserver.current.observe(item);
      }
    },
    [hasError, hasNextPage, isLoading]
  );

  function exitEdit() {
    setEdit(false);
    setMake(false);
    toast({
      title: "Редактирование завершено",
      description: "Выход из режима редактирования свойств класса",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  }

  function startEdit() {
    setEdit(true);
    toast({
      title: "В редактировании",
      description: "Вход в режим редактирования свойств класса",
      status: "warning",
      duration: 3000,
      isClosable: true,
    });
  }

  function deleteItem(item: Properties) {
    outerStorage?.deleteData(item.id).then((data: any) => {
      console.log(data, "deleted!");
      toast({
        title: "Удалено",
        description: "Элемент успешно удалён",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    });
  }

  function saveItem(
    item: any,
    info: { numbers: string[]; booleans: string[]; required: string[] },
    addFields: (item: any) => any,
    callback: (item: any) => void
  ) {
    makeNewEntity(
      info.numbers,
      info.booleans,
      info.required,
      item,
      addFields,
      () => {
        outerStorage?.putData(item).then((data: any) => {
          console.log("saved!");
          callback(item);
          toast({
            title: "Сохранено",
            description: "Результат успешно сохранен",
            status: "info",
            duration: 3000,
            isClosable: true,
          });
        });
      }
    );
  }

  function save() {
    saveItem(
      active,
      info,
      (item) => {
        item["owner_id"] = data.item.id;
        return item;
      },
      () => {
        console.log("Success at save!");
      }
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      scrollBehavior="inside"
    >
      <ModalContent>
        {!edit && <ModalCloseButton />}
        <ModalHeader>
          <Center>
            <Heading>
              {edit
                ? `Редактирование экземпляров класса ${data.item["name"]}`
                : `Экземпляры класса ${data.item["name"]}`}
            </Heading>
          </Center>
          <Flex>
            <Spacer />
            <Stack>
              <Button
                variant="link"
                onClick={() =>
                  startRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  })
                }
              >
                К началу списка
              </Button>
              <Button
                variant="link"
                onClick={() =>
                  endRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  })
                }
              >
                К концу списка
              </Button>
            </Stack>
            <Spacer />
            <Text fontSize="md" alignSelf={"center"} mr={3}>
              Отображать по:
            </Text>
            <Select
              w={85}
              value={itemsPerPage}
              onChange={(e) => {
                const perPage = Number.parseInt(e.currentTarget.value);
                setItemsPerPage(perPage);
                setCurrentPage(result.length / perPage);
              }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={0}>Все</option>
            </Select>
            <Spacer />
          </Flex>
        </ModalHeader>
        <ModalBody
          m={2.5}
          border="1px"
          borderColor="gray.600"
          borderRadius="0.5em"
          padding={0}
        >
          {edit && make ? (
            <>
              <DynamicForm
                setError={setError}
                classProps={propsForItem}
                required={info.required}
                booleans={info.booleans}
                numbers={info.numbers}
                item={active}
                setItem={setActive}
                isEdit={edit}
              />
              <Collapse in={edit} animateOpacity>
                <Stack>
                  <Button
                    leftIcon={<IoSaveSharp />}
                    variant="outline"
                    my={2}
                    onClick={save}
                    isDisabled={error}
                  >
                    Сохранить изменения
                  </Button>
                  <Button
                    leftIcon={<IoBanSharp />}
                    variant="outline"
                    my={2}
                    onClick={() => {
                      setActive({});
                      setMake(false);
                    }}
                  >
                    Отменить создание
                  </Button>
                </Stack>
              </Collapse>
            </>
          ) : (
            <TableWithState
              startRef={startRef}
              properties={data.props}
              onChoose={(item) => {
                setActive(item);
              }}
              entities={result}
              ref={lastItem}
            />
          )}
        </ModalBody>
        <ModalFooter justifyContent="center">
          {edit && (
            <Stack>
              <ButtonsAndModal
                isDelete={isDelete}
                onCancel={onCancel}
                deleteItem={() => {
                  deleteItem(active as Properties);
                }}
                edit={edit}
                setMake={setMake}
                openDelete={onDelete}
                chosenItem={active}
                onAddNew={() => {
                  setActive({});
                }}
              />
              <Tooltip label="Закончить редактирование">
                <IconButton onClick={exitEdit} aria-label="quit edit">
                  <IoBanSharp />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
          {edit === false && (
            <Tooltip label="Редактировать">
              <IconButton onClick={startEdit} aria-label="edit form">
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
