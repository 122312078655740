import { extendTheme } from "@chakra-ui/react";

import {
  darkAbstractNodesColor,
  darkBackgroundColor,
  darkNodesColor,
  lightAbstractNodesColor,
  lightBackgroundColor,
  lightNodesColor,
  themeColors,
} from "./colors";
import { mode } from "@chakra-ui/theme-tools";
import { tableTheme } from "./components/tableStyle";

const amazingTheme = {
  fonts: {
    heading: `'Nunito', normal`,
    body: `'Open Sans', sans-serif`,
    code: `source-code-pro, 'Menlo', 'Monaco', 'Consolas', 'Courier New', monospace;`,
  },
  shadows: {
    outline: "0 0 3px 3px var(--chakra-colors-blue-300)",
  },
  colors: themeColors,
  components: {
    Button: {
      baseStyle: {
        borderRadius: 20,
      },
    },
    Drawer: {
      variants: {
        alwaysOpen: {
          parts: ["dialog, dialogContainer"],
          dialog: {
            border: "1px solid black",
            pointerEvents: "auto",
          },
          dialogContainer: {
            pointerEvents: "none",
          },
        },
      },
    },
    Table: tableTheme,
    Block: {
      baseStyle: {
        background: "white",
        textAlign: "center",
        padding: "5px",
        border: "1px solid black",
        borderRadius: "15px",
        height: "50px",
      },
      variants: {
        abstract: (props: any) => ({
          bg: mode(lightAbstractNodesColor, darkAbstractNodesColor)(props),
        }),
        real: (props: any) => ({
          bg: mode(lightNodesColor, darkNodesColor)(props),
        }),
        userAbstract: (props: any) => {
          const color = mode(
            lightAbstractNodesColor,
            darkAbstractNodesColor
          )(props);
          const bg = mode(lightBackgroundColor, darkBackgroundColor)(props); // Задний фон
          return {
            bgGradient: `repeating-linear(45deg, ${color},  ${color} 5px, ${bg} 5px, ${bg} 10px)`,
          };
        },
        userReal: (props: any) => {
          const color = mode(lightNodesColor, darkNodesColor)(props);
          const bg = mode(lightBackgroundColor, darkBackgroundColor)(props); // Задний фон
          return {
            bgGradient: `repeating-linear(45deg, ${color},  ${color} 5px, ${bg} 5px, ${bg} 10px)`,
          };
        },
      },
    },
    Input: {
      defaultProps: {
        variant: "filled",
      },
    },
  },
};

const config = {
  initialColorMode: "dark",
  useSystemColorMode: true,
};

const theme = extendTheme(amazingTheme, { config });

export default theme;
